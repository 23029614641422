import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import Tile from "../../components/Tile.js";
import withNeon, { fx } from "react-neon";
const effect = new fx.Torch();
effect.listenMouse();
const Torch = withNeon(Tile, effect);
export const frontmatter = {
  title: 'Torch',
  author: 'Chris Neale',
  draft: false,
  date: '2018-11-29T23:46:37.121Z',
  tags: ['effect']
}
export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}>

<MDXTag name="h3" components={components}>{`Effects / Toon`}</MDXTag>
<MDXTag name="p" components={components}>{`A torch effect that lights a section of a component`}</MDXTag>
<MDXTag name="h3" components={components}>{`Options`}</MDXTag>
<MDXTag name="p" components={components}>{`Torch has no options.`}</MDXTag>
<MDXTag name="h3" components={components}>{`Example`}</MDXTag>
<Torch />
<MDXTag name="h3" components={components}>{`Example Code`}</MDXTag>
<div className="gatsby-highlight" data-language="javascript"><pre className="language-javascript"><code className="language-javascript"><span className="token keyword">import</span> Tile <span className="token keyword">from</span> <span className="token string">"../../components/Tile.js"</span><span className="token punctuation">;</span>{"\n"}<span className="token keyword">import</span> withNeon<span className="token punctuation">,</span> <span className="token punctuation">{"{"}</span> fx <span className="token punctuation">{"}"}</span> <span className="token keyword">from</span> <span className="token string">"react-neon"</span><span className="token punctuation">;</span>{"\n"}<span className="token keyword">const</span> effect <span className="token operator">=</span> <span className="token keyword">new</span> <span className="token class-name">fx<span className="token punctuation">.</span>Torch</span><span className="token punctuation">(</span><span className="token punctuation">)</span><span className="token punctuation">;</span>{"\n"}effect<span className="token punctuation">.</span><span className="token function">listenMouse</span><span className="token punctuation">(</span><span className="token punctuation">)</span><span className="token punctuation">;</span>{"\n"}<span className="token keyword">const</span> Torch <span className="token operator">=</span> <span className="token function">withNeon</span><span className="token punctuation">(</span>Tile<span className="token punctuation">,</span> effect<span className="token punctuation">)</span><span className="token punctuation">;</span></code></pre></div>
</MDXTag>

export const _frontmatter = {};

  